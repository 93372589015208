import React from 'react'

import Terminal from '../components/terminal'
import TerminalItem from '../components/terminal-item'

const PageNotFound = () => (
  <>
    <Terminal icon="exclamation-triangle" title="404" command="404">
      <TerminalItem>You have reached the end of the internet.</TerminalItem>
    </Terminal>
  </>
)

export default PageNotFound
